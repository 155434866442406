<template>
  <div>
    <b-card title="Nuevo Ajuste Negativo (-)">
      <!-- <validation-observer ref="simpleRules">
        <b-form> -->
      <hr class="hr" />
      <b-row class="mb-0-5">
        <b-col cols="12" lg="6" class="border-right">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mb-0-5">
                <b-col cols="12" class="mb-0-5">
                  <b-form-group
                    label="Tipo ajuste:"
                    label-for="tipo_ajuste"
                    label-cols="12"
                    label-cols-lg="2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo ajuste"
                      rules="required"
                    >
                      <b-form-select
                        id="tipo_ajuste"
                        size="sm"
                        v-model="transaccion.tipo_ajuste"
                        value-field="value"
                        text-field="text"
                        :options="tipoAjusteItems"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Observaciones:"
                    label-for="observaciones"
                    label-cols="12"
                    label-cols-lg="2"
                  >
                    <b-form-textarea
                      id="observaciones"
                      placeholder="Observaciones"
                      v-model="transaccion.observaciones"
                      lazy-formatter
                      :formatter="textFormatter"
                      :rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>

        <b-col cols="12" lg="6">
          <validation-observer ref="ajusteRules">
            <b-form>
              <b-row class="mb-0-5">
                <b-col cols="6" lg="8">
                  <b-form-group
                    label="Código (Esc):"
                    label-for="codigo"
                    label-cols="12"
                    label-cols-lg="3"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MaximizeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="codigo"
                        v-model="codigo"
                        @keyup.enter="buscarCodigo()"
                        onClick="this.select();"
                        size="sm"
                        placeholder="Digite o Escanee el código"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-button
                    class="btnBuscarArticulo"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    block
                    @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                  >
                    BUSCAR PRODUCTO (F9)
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mb-0-5">
                <b-col cols="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label="* PRODUCTO "
                    label-for="producto"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Producto"
                      rules="required"
                    >
                      <b-form-input
                        id="producto"
                        :value="producto.descripcion"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-0-5">
                <b-col cols="12" lg="2">
                  <b-form-group
                    label-cols="12"
                    label="* CANTIDAD ITEM "
                    label-for="producto"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Producto"
                      rules="required"
                    >
                      <b-form-select
                        id="tipo_ajuste"
                        size="sm"
                        v-model="detalleProducto.id"
                        placeholder="Seleccionar"
                        value-field="id"
                        text-field="cantidad"
                        @change="cargarLoteVencimiento()"
                        :options="detalleProductoItems"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group
                    label-cols="12"
                    label="* LOTE "
                    label-for="lote"
                  >
                    <b-form-input
                      id="lote"
                      :value="detalleProducto.lote"
                      disabled
                      size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group
                    label-cols="12"
                    label="* VENCIMIENTO "
                    label-for="vencimiento"
                  >
                    <b-form-input
                      id="vencimiento"
                      :value="detalleProducto.vencimiento"
                      disabled
                      size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group
                    label-cols="12"
                    label="* CANTIDAD AJUSTE (-)"
                    label-for="cantidad"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cantidad"
                      :rules="`required|max_value:${detalleProducto.cantidadActual}`"
                    >
                      <b-form-input
                        id="cantidad"
                        v-model="detalleProducto.cantidad"
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group label-cols="12" label-for="producto">
                    <b-button
                      class="btnBuscarArticulo mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="dark"
                      size="sm"
                      @click.prevent="validarAjuste()"
                      block
                    >
                      AGREGAR
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <hr class="hr" />
      <b-row hidden>
        <b-col cols="12" lg="6" class="mb-0-5">
          <b-row>
            <b-col cols="6" lg="9">
              <b-form-group
                label="Código (Esc):"
                label-for="codigo"
                label-cols="12"
                label-cols-lg="3"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MaximizeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="codigo"
                    v-model="codigo"
                    @keyup.enter="buscarCodigo()"
                    onClick="this.select();"
                    size="sm"
                    placeholder="Digite o Escanee el código"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="6" lg="3">
              <b-button
                class="btnBuscarArticulo"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                block
                @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
              >
                BUSCAR ARTICULO (F9)
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- </b-form>
      </validation-observer> -->

      <b-row class="mb-1">
        <b-col cols="12">
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-balham ag-grid-tableAjusteInventario"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :rowData="transaccion.detalleTransaccion"
            :suppressRowClickSelection="true"
            colResizeDefault="shift"
            :animateRows="false"
            :floatingFilter="false"
            :rowClassRules="rowClassRules"
            @rowClicked="onRowClicked($event)"
            :alwaysShowVerticalScroll="true"
            @first-data-rendered="onFirstDataRendered"
            :getRowStyle="getRowStyle"
            :pinnedBottomRowData="pinnedBottomRowData"
            @cell-value-changed="onCellValueChanged"
            @cell-key-down="onCellKeyDown"
          >
          </ag-grid-vue>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="12" md="4">
          <b-form-group style="text-align: end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="dark"
              class="mr-1"
              @click.prevent="validar"
            >
              REALIZAR AJUSTE NEGATIVO
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <ModalSelectArticulo
        ref="ModalSelectArticuloRef"
        esServicio="0"
        :ocultarCostoColumna="$hascan('venta-ver_costo')"
        @selectItemArticulo="selectItemArticulo"
      />
      <ModalPdfTransaccion
        :pdf="pdf"
        :pdfBase64="pdfBase64"
        :accionModalPdf="accionModalPdf"
        @cerrarModalPdf="cerrarModalPdf"
      />
    </b-card>
  </div>
</template>
<script>
import AjusteServices from "../services/index";
import ProductoServices from "@/modules/administracion/producto/services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";
import AjustesInventarioEnum from "@/utils/enums/AjustesInventarioEnum";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";

export default {
  name: "NuevoAjusteNegativoLayout",
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
    ModalPdfTransaccion,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // tipo de ajuste
      giroComercial: "farmacia",
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // modal articulo
      item: [],
      // table
      required,
      loading: false,

      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "EXISTENCIA",
          field: "stock",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "PRODUCTO",
          field: "descripcion",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "LOTE",
          field: "lote",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
        },
        {
          headerName: "VENCIMIENTO",
          field: "vencimiento",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
        },
        {
          headerName: "",
          field: "info",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "PRECIO",
          field: "precio",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: costoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return false;
            } else {
              return false;
            }
          },
          hide: true,
          singleClickEdit: true,
        },
        {
          headerName: "COSTO",
          field: "costo",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: costoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subtotal =
                param.data.cantidad * param.data.costo - param.data.descuento;
              return param.data.subtotal;
            }
            return param.node.data.subtotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>`;
            }
          },
        },
      ],
      rowClassRules: {
        "row-red": "(data.stock < data.cantidad) && (data.esServicio)==0",
      },
      // end grid
      submitted: false,
      // transaccion
      transaccion: {
        cliente_proveedor_id: null,
        usuario_id: null,
        codigo_metodo_pago: null,
        metodo_pago: "",
        tipo_transaccion_id: 7,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: "",
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
        observaciones: "",
        tipo_ajuste: null,
      },
      // tipoAjusteItems: [],
      // variables de clientes
      errores: [],
      codigo: "",
      producto: {},
      detalleProductoItems: [],
      detalleProducto: {
        id: null,
        lote: "",
        vencimiento: "",
        cantidadActual: 0,
        cantidad: "",
      },
    };
  },
  watch: {
    "transaccion.tipo_ajuste": function () {
      this.errores.tipo_ajuste = [];
    },
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          info: "SUBTOTAL Bs",
          subtotal: this.calcularSubTotal(),
        },
        {
          info: "TOTAL Bs",
          subtotal: this.calcularTotal(),
          bold: true,
        },
      ];
    },
    tipoAjusteItems() {
      return AjustesInventarioEnum.ajusteNegativo.map((option) => ({
        value: option,
        text: option,
      }));
    },
  },
  methods: {
    async validarAjuste() {
      this.$refs.ajusteRules.validate().then((success) => {
        if (success) {
          setTimeout(() => {
            this.agregarItem(this.producto, this.detalleProducto, false);
          }, 100);
        } else {
          this.$bvToast.toast(" DEBE SELECCIONAR EL PRODUCTO. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },
    cargarLoteVencimiento() {
      if (this.detalleProducto.id) {
        const detalleProducto = this.detalleProductoItems.find(
          (item) => item.id === this.detalleProducto.id
        );
        this.detalleProducto.lote = detalleProducto.lote;
        this.detalleProducto.vencimiento = detalleProducto.vencimiento;
        this.detalleProducto.cantidadActual = detalleProducto.cantidad;
        this.detalleProducto.cantidad = "";
      } else {
        this.detalleProducto.lote = "";
        this.detalleProducto.vencimiento = "";
        this.detalleProducto.cantidadActual = 0;
        this.detalleProducto.cantidad = 0;
      }
    },
    onkey(event) {
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    // VALIDAR FORMULARIO
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.transaccion.detalleTransaccion.length > 0) {
            this.submitted = true;
            this.transaccion.sede_id = this.mixing.sede_id;
            this.registrar();
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },
    async registrar() {
      try {
        const response = await AjusteServices.store(this.transaccion);
        this.errores = [];
        this.limpiarForm();
        setTimeout(() => {
          this.submitted = false;
          this.$bvToast.toast("Ajuste Negativo se realizo con corretamente", {
            title: "EXITO!",
            variant: "success",
            solid: false,
          });
          this.verPdf(response.data.id);
        }, 300);
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await AjusteServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.transaccion.detalleTransaccion.splice(index, 1);
    },
    // calculos de totales
    calcularSubTotal() {
      let resultado = 0;
      for (
        let index = 0;
        index < this.transaccion.detalleTransaccion.length;
        index++
      ) {
        resultado =
          resultado + this.transaccion.detalleTransaccion[index].subtotal;
      }
      resultado = resultado.toFixed(2);
      // this.transaccion.subtotal = parseFloat(resultado);
      this.transaccion.subtotal = resultado;
      return resultado;
    },
    calcularTotal() {
      let resultado = 0;
      resultado =
        parseFloat(this.transaccion.subtotal) -
        parseFloat(this.transaccion.descuento);
      resultado = resultado.toFixed(2);
      // this.transaccion.total = parseFloat(resultado);
      this.transaccion.total = resultado;
      return resultado;
    },

    onCellValueChanged(event) {
      if (event.data.key == "descuento") {
        this.transaccion.descuento = event.data.subtotal;
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    // limpiar formulario
    limpiarForm() {
      this.transaccion = {
        cliente_proveedor_id: null,
        codigo_metodo_pago: null,
        metodo_pago: "",
        tipo_transaccion_id: 1,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        usuario_id: this.mixing.id,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: this.mixing.sede_id,
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
        observaciones: "",
        tipo_ajuste: null,
      };
      this.codigo = "";
      this.producto = {};
      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
    },

    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        try {
          const response = await ProductoServices.findOneCodigoNoStock(
            this.codigo,
            this.mixing.sede_id
          );
          this.selectItemArticulo(response.data, false);
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    // encuentra articulo
    encuentraArticulo(id) {
      var sw = 0;
      for (let i = 0; i < this.transaccion.detalleTransaccion.length; i++) {
        if (this.transaccion.detalleTransaccion[i].id == id) {
          this.$bvToast.toast(" EL PRODUCTO YA FUE AGREGADO ", {
            title: "ADVERTENCIA  !",
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: false,
          });
          // setTimeout(() => {
          //   this.focusAgridCantidad(i);
          // }, 120);
          sw = true;
        }
      }
      return sw;
    },
    // Emit de select articulo
    async selectItemArticulo(producto) {
      const response = await ProductoServices.findOneBySedeId(
        producto.id,
        this.mixing.sede_id
      );
      this.detalleProductoItems = response.data.productos_detalles;
      this.producto = producto;
      // if (producto) {
      //   setTimeout(() => {
      //     this.agregarItem(producto, estado);
      //   }, 100);
      // } else {
      //   this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
      //     title: "ADVERTENCIA  !",
      //     variant: "warning",
      //     toaster: "b-toaster-top-center",
      //     solid: false,
      //   });
      // }
    },
    agregarItem(producto, detalleProducto, estado) {
      if (!this.encuentraArticulo(producto.id, estado)) {
        if (detalleProducto.cantidadActual >= 1) {
          // if (producto.stock >= 1) {
          const detalle = this.pedido?.transaccion.detalle_transaccion.find(
            (detalle) => {
              if (detalle.producto.id == producto.id) {
                return detalle;
              }
            }
          );
          const cantidad = this.pedido ? detalle.cantidad : 1;
          const costo = this.pedido ? detalle.costo : producto.costo;
          const subtotal = costo * cantidad;
          this.transaccion.detalleTransaccion.push({
            // id: producto.id,
            // cantidad: cantidad,
            // precio: producto.precio_unitario,
            // stock: parseFloat(producto.stock),
            id: producto.id,
            cantidad: detalleProducto.cantidad,
            codigo: producto.codigo,
            descripcion: producto.descripcion,
            descuento: 0,
            costo: costo,
            lote: detalleProducto.lote,
            vencimiento: detalleProducto.vencimiento,
            producto_detalle_id: detalleProducto.id,
            stock: parseFloat(detalleProducto.cantidadActual),
            precio: 0,
            subtotal: subtotal,
            unidad: producto.unidad,
          });
          if (estado) {
            setTimeout(() => {
              this.focusAgrid();
            }, 120);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 120);
            this.gridApi.redrawRows();
          }
        } else {
          this.$bvToast.toast("LA CANTIDAD SUPERA AL STOCK", {
            title: "ADVERTENCIA!",
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      }
      this.detalleProductoItems = [];
      this.detalleProducto = {
        id: null,
        lote: "",
        vencimiento: "",
        cantidadActual: 0,
        cantidad: "",
      };
      this.producto = {};
      this.$refs.ajusteRules.reset();
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },

    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
      this.$emit("compra-registrada");
    },

    async buscarProductosPedidosPorIds(ids) {
      if (ids.length > 0) {
        try {
          const response = await ProductoServices.findByIds(
            ids,
            this.mixing.sede_id
          );
          const productos = response.data;

          productos.forEach((producto) => {
            this.selectItemArticulo(producto, false);
          });
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
    this.transaccion.usuario_id = this.mixing.id;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    if (!params.data.esServicio) {
      if (parseFloat(params.newValue) > parseFloat(params.data.stock)) {
        return params.data.cantidad;
      } else {
        return Number(params.newValue).toFixed(2);
      }
    } else {
      return Number(params.newValue).toFixed(2);
    }
  } else {
    return params.data.cantidad;
  }
}
function costoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.costo;
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 26.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.box {
  height: 9rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}

.btnBuscarArticulo {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .btnBuscarArticulo {
    margin-top: 0;
  }
}
</style>